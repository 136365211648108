.currentMarkerControlDivCLASS:hover .currentMarkerEditIcon {
  opacity: 1;
}

.currentMarkerEditIcon {
  opacity: 0.4;
}

.cursor-default {
  cursor: default;
}

.blockie {
  border-radius: 50%;
}

.accountBlockie {
  border-radius: 50%;
  border: 3px solid black;
  cursor: pointer;
}

.headerLink {
  text-decoration: none !important;
  color: black !important;
}

.headerMenuItem {
  font-size: 20px !important;
  font-weight: bold;
  text-decoration: none !important;
  color: black !important;
}

.formLabel {
  margin-top: 32px;
  margin-bottom: 4px;
}

.paymentSettingFormLabel {
  margin-top: 16px;
  margin-bottom: 4px;
}

.formInfo {
  font-size: x-small;
  line-height: 1.5;
  margin-top: 8px;
}

.buttonLink {
  text-decoration: none !important;
}

@keyframes breath {
  0% {
    width: 80px;
  }
  50% {
    width: 92px;
  }
  100% {
    width: 80px;
  }
}

.breathingLogo {
  animation: breath 3s ease-in-out infinite;
  transform: translate3d(0, 0, 0);
  width: 100%;
  max-height: 96px;
}
